<template>
  <div class="main-content q-pa-md">
    <Mural :breadcrumbs="breadcrumbs" route="knowledge" :query="{}">
      <DefaultBtn
        titleButton="Salvar"
        icon="edit"
        @click="(e) => validateAndAddTrail(e)"
      />
    </Mural>
    <div class="content-new-training flex row col-12">
      <div class="content-thumb-training col-4">
        <div class="col-12 flex row q-pa-sm">
          <div class="col-12">
            <label class="title-form">Capa</label>
          </div>
          <div class="col-12 thumb-training q-mt-sm shadow-3">
            <q-img
              height="150px"
              :src="imageUrl"
              fit="contain"
              style="background-color: #e5e5e5"
            />
          </div>
          <div class="col-12 btn-thumb-training flex justify-between q-mt-md">
            <q-btn
              @click="sendEventAddImage"
              class="q-mt-xs"
              unelevated
              color="default-pink"
              text-color="default-white"
              label="Escolher imagem"
            />
            <input
              style="display: none"
              ref="refFileInput"
              @change="onEventFilePicked"
              type="file"
              name="upload"
              accept="image/*"
            />

            <q-btn
              @click="sendEventRemoveImage"
              class="q-mt-xs"
              outline
              color="default-pink"
              label="Remover"
            />
          </div>
          <q-separator inset color="grey-5" />
          <q-checkbox
            class="col-12 row flex"
            v-model="trail.editionOtherTutor"
            label="Ativar edição compartilhada"
            color="default-pink"
          />

          <div class="col-12 row flex q-pb-sm q-mt-lg">
            <div class="flex justify-center items-center">
              <label class="h5"
                >Pontuação do curso - Valor Padrão ({{ pointEndCourse }})</label
              >
            </div>
            <q-input
              class="col-4"
              bg-color="grey-3"
              v-model="trail.points"
              color="default-pink"
              dense
              type="number"
              outlined
            />
          </div>
        </div>
      </div>
      <div class="content-form-training col-8">
        <form>
          <div class="col-12 flex row q-pa-sm">
            <div class="form-input col-3 column">
              <div class="row">
                <span class="title-input-form">Status</span>
              </div>
              <div>
                <q-toggle v-model="status" color="green" />
              </div>
            </div>
            <div class="form-input col-3 column">
              <div class="row">
                <span class="title-input-form title-input-form--required"
                  >Início</span
                >
              </div>
              <div class="row">
                <q-input
                  class="col-12"
                  bg-color="grey-3"
                  v-model="trail.start_date"
                  dense
                  type="date"
                  outlined
                  :rules="[
                    (val) => validateDate(val, 'start', null, trail.end_date),
                  ]"
                />
              </div>
            </div>
            <div class="form-input col-3 offset-1 column">
              <div class="row">
                <span class="title-input-form title-input-form--required"
                  >Fim</span
                >
              </div>
              <div class="row">
                <q-input
                  class="col-12"
                  bg-color="grey-3"
                  v-model="trail.end_date"
                  dense
                  type="date"
                  outlined
                  :rules="[
                    (val) => validateDate(val, 'end', trail.start_date, null),
                  ]"
                />
              </div>
            </div>
          </div>
          <div class="col-12 flex row q-pa-sm">
            <div class="form-input col-12 column">
              <div class="row">
                <span class="title-input-form title-input-form--required"
                  >Nome da Trilha</span
                >
              </div>
              <div class="row">
                <q-input
                  class="col-12"
                  bg-color="grey-3"
                  v-model="trail.title"
                  color="default-pink"
                  dense
                  type="text"
                  outlined
                  :rules="[
                    (val) => !!val || 'O campo nome da trilha é obrigatório.',
                  ]"
                />
              </div>
            </div>
          </div>
          <div class="col-12 flex row q-pa-sm">
            <div class="form-input col-12 column">
              <div class="row">
                <span class="title-input-form title-input-form--required"
                  >Classificação</span
                >
              </div>
              <div class="row">
                <q-select
                  outlined
                  bg-color="grey-3"
                  color="default-pink"
                  class="col-12"
                  dense
                  emit-value
                  map-options
                  v-model="trail.classification"
                  :options="classification"
                  :rules="[
                    (val) => !!val || 'O campo classificação é obrigatório.',
                  ]"
                />
              </div>
            </div>
          </div>
          <div class="col-12 flex row q-pa-sm">
            <div class="form-input col-12 column">
              <div class="row">
                <span class="title-input-form title-input-form--required"
                  >Categoria</span
                >
              </div>
              <div class="row">
                <q-select
                  outlined
                  bg-color="grey-3"
                  color="default-pink"
                  class="col-6"
                  dense
                  v-model="trail.categoryId"
                  :options="listCategories"
                  :rules="[
                    (val) => !!val || 'O campo categoria é obrigatório.',
                  ]"
                />
                <div class="col-4 q-ml-xl" style="height: 0">
                  <OutlineBtn
                    titleButton="Cadastrar categorias"
                    :showIcon="false"
                    @addNewPost="showDialogCategory = true"
                    v-if="canUserAccessClass.canUserAccess('courses-category-add', false)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 flex row q-pa-sm">
            <div class="form-input col-12 column">
              <div class="row">
                <span class="title-input-form">Dependência</span>
              </div>
              <div class="row">
                <q-select
                  outlined
                  bg-color="grey-3"
                  color="default-pink"
                  class="col-12"
                  dense
                  emit-value
                  map-options
                  v-model="trail.requisite_course_id"
                  :options="courses"
                  use-input
                  input-debounce="0"
                  @filter="filterFnCourse"
                />
              </div>
            </div>
          </div>
          <div class="col-12 flex row q-pa-sm q-mt-md">
            <div class="form-input col-12 column">
              <div class="row">
                <span class="title-input-form title-input-form--required"
                  >Descrição Curta</span
                >
              </div>
              <div class="row">
                <q-input
                  class="col-12"
                  v-model="trail.short_desc"
                  bg-color="grey-3"
                  color="default-pink"
                  dense
                  type="text"
                  outlined
                  :rules="[
                    (val) => !!val || 'O campo Descrição curta é obrigatório.',
                  ]"
                />
              </div>
            </div>
          </div>
          <div class="col-12 flex row q-pa-sm">
            <div class="form-input col-12 column">
              <div class="row">
                <span class="title-input-form title-input-form--required"
                  >Descrição Completa</span
                >
              </div>
              <div class="row">
                <q-input
                  class="col-12"
                  v-model="trail.description"
                  bg-color="grey-3"
                  color="default-pink"
                  dense
                  type="textarea"
                  outlined
                  :rules="[
                    (val) => !!val || 'O campo Descrição é obrigatório.',
                  ]"
                />
              </div>
            </div>
          </div>
          <div class="col-12 q-pa-sm">
            <div class="form-input col-12 column">
              <div class="row">
                <span class="title-input-form title-input-form--required"
                  >Tipo de trilha</span
                >
              </div>
            </div>
            <div class="row q-mb-md">
              <q-tooltip
                anchor="center left"
                self="top middle"
                v-if="isEditMode"
              >
                Você não pode editar essa informação!
              </q-tooltip>
              <q-radio
                v-model="trail.course_type"
                color="default-pink"
                :disable="isEditMode"
                :val="1"
                label="Para grupos"
              >
              </q-radio>
              <q-radio
                v-model="trail.course_type"
                :val="2"
                :disable="isEditMode"
                color="default-pink"
                label="Para usuários"
              >
              </q-radio>
            </div>
            <div
              class="container-participants"
              v-if="trail.course_type != null && !isEditMode"
            >
              <div>
                <div class="">
                  <label class="q-mr-sm">Segmentos</label>
                  <q-img
                    src="@/assets/icons/info_cicle.svg"
                    width="16px"
                    height="16px"
                  />
                  <q-tooltip anchor="top left" self="top left">
                    Utilize os segmentos para poder filtrar os grupos
                  </q-tooltip>
                </div>
                <q-select
                  dense
                  outlined
                  color="default-pink"
                  bg-color="grey-3"
                  class="col-11"
                  id="semgments"
                  multiple
                  use-chips
                  use-input
                  emit-value
                  map-options
                  @filter="filterFnBranches"
                  v-model="trail.branches"
                  :options="listBranches"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">
                        Não encontrado
                      </q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>
              <div class="container-groups">
                <div class="item-groups">
                  <label v-if="trail.course_type == 1" for="">Grupos</label>
                  <div v-else>
                    <label for="" class="q-mr-sm">Grupos</label>
                    <q-img
                      src="@/assets/icons/info_cicle.svg"
                      width="16px"
                      height="16px"
                    />
                    <q-tooltip anchor="top left" self="top left">
                      Utilize os grupos para poder filtrar os usuários
                    </q-tooltip>
                  </div>
                  <q-select
                    dense
                    outlined
                    color="default-pink"
                    bg-color="grey-3"
                    class="col-11"
                    multiple
                    use-input
                    use-chips
                    emit-value
                    :disable="trail.branches.length > 0 ? false : true"
                    @filter="filterFnGroups"
                    map-options
                    v-model="trail.groups"
                    :options="optionsGroups"
                    @update:model-value="handleSelectionOfGroups"
                  >
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">
                          Não encontrado
                        </q-item-section>
                      </q-item>
                    </template>
                  </q-select>
                </div>
                <q-checkbox
                  left-label
                  v-model="allGroups"
                  label="Selecionar todos"
                  :disable="trail.branches.length > 0 ? false : true"
                  color="default-pink"
                  @update:model-value="selectedFilteredGroups"
                />
              </div>
              <div class="container-users" v-if="trail.course_type == 2">
                <div class="item-users">
                  <label for="">Usuários</label>
                  <q-select
                    dense
                    outlined
                    color="default-pink"
                    bg-color="grey-3"
                    class="col-11"
                    multiple
                    use-input
                    use-chips
                    :disable="trail.groups.length > 0 ? false : true"
                    @filter="filterFnUsers"
                    map-options
                    v-model="trail.participants"
                    :options="optionsUsers"
                  >
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey">
                          Não encontrado
                        </q-item-section>
                      </q-item>
                    </template>
                  </q-select>
                </div>
                <q-checkbox
                  left-label
                  :disable="trail.groups.length > 0 ? false : true"
                  v-model="allUsers"
                  label="Selecionar todos"
                  color="default-pink"
                  @update:model-value="selectedAllUsers"
                />
              </div>
            </div>
          </div>
        </form>
        <AddCategotyDialog
          title="Adicionar novas categorias para trilha"
          :listCategory="listCategories"
          :showDialog="showDialogCategory"
          :canEdit="false"
          :canRemove="true"
          @addNewCategory="(newName, event) => addNewCategory(newName, event)"
          @close="() => (showDialogCategory = false)"
          @disableCategory="
            (category, event) => removeCategory(category, event)
          "
        />
      </div>
    </div>
    <q-inner-loading
      :showing="loading"
      label="Por favor aguarde..."
      label-class="text-teal"
      label-style="font-size: 1.1em"
    />
  </div>
</template>

<script>
import Mural from "@/components/Mural.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import InputNumber from "@/components/shared/inputs/InputNumber.vue";
import AddCategotyDialog from "@/components/shared/Dialog/AddCategotyDialog.vue";
import OutlineBtn from "@/components/shared/buttons/OutlineBtn.vue";
import PointServices from "@/services/PointServices";
import TrailDataServices from "@/services/TrailDataServices";
import GroupServices from "@/services/GroupServices";
import UserServices from "@/services/UserServices";

import { ENV } from "@/utils/env";
import { date, useQuasar } from "quasar";

//----VUE
import { useStore } from "vuex";
import { ref, reactive, onMounted, watch, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import CanUserAccessClass from "@/utils/CanUserAccessClass";

export default {
  name: "NewTraining",
  components: {
    Mural,
    DefaultBtn,
    InputNumber,
    AddCategotyDialog,
    OutlineBtn,
  },
  setup() {
    //----CONSTANTS
    const breadcrumbs = ref(["Adicionar trilha"]);
    const refFileInput = ref(null);
    const url_aws_bucket = ENV.URL_AWS_BUCKET;
    const store = useStore();
    const route = new useRoute();
    const router = new useRouter();
    const $q = new useQuasar();
    const courseId = route.query.id;
    const allUsers = ref(false);

    //----SERVICES
    const _pointServices = new PointServices();
    const trailDataServices = new TrailDataServices();
    const _GroupServices = new GroupServices();
    const _userServices = new UserServices();

    const canUserAccessClass = new CanUserAccessClass();

    //----VARIABLES
    let pointEndCourse = ref("");
    let status = ref(true);
    let classification = ref([]);
    let listCategories = ref([]);
    let showDialogCategory = ref(false);
    let loading = ref(false);
    let courses = ref([]);
    let optionCourses = ref([]);
    let imageUrl = ref("");
    let file = ref(null);
    let trail = reactive({
      title: "",
      short_desc: "",
      description: "",
      classification: "",
      start_date: "",
      end_date: "",
      categoryId: null,
      requisite_course_id: null,
      points: null,
      editionOtherTutor: false,
      course_type: null,
      branches: [],
      groups: [],
      participants: [],
    });
    let listBranches = ref([]);
    let optionListBranches = ref([]);
    let allGroups = ref(false);
    let listGroups = ref([]);
    let optionsGroups = ref([]);
    let listUsers = ref([]);
    let optionsUsers = ref([]);

    const isEditMode = computed(() => (courseId != null ? true : false));

    onMounted(async () => {
      _getClassification();
      _getCourses();
      _getCategory();
      await _getEventPoint();
      GetTrail(route.query.id);
      if (!isEditMode.value) {
        getAllGroups();
        getBranches();
      }
    });

    watch(
      () => [trail.groups],
      ([newGroup], [oldGroup]) => {
        if (newGroup.length != 0) {
          trail.participants = trail.participants.filter((participant) => {
            return newGroup.includes(participant.group_id);
          });
        } else {
          trail.participants = [];
          allUsers.value = false;
        }
      }
    );

    watch(
      () => trail.course_type,
      (newType, oldType) => {
        if (newType == 1) {
          handleSelectionOfGroups();
        }
      }
    );

    watch(
      () => trail.branches,
      (newBranch, oldBranch) => {
        if (newBranch.length == 0) {
          trail.groups = null;
          trail.groups = [];
          allGroups.value = false;
          optionsUsers.value = [];
          listUsers.value = [];
          trail.participants = [];
        }

        if (newBranch.length != oldBranch.length) {
          optionsGroups.value = listGroups.value;
        }

        if (newBranch.length != 0) {
          optionsGroups.value = listGroups.value.filter((group) => {
            return newBranch.includes(group.branch_id);
          });

          let groupValues = optionsGroups.value.map((group) => {
            return group.value;
          });

          trail.groups = groupValues.filter((value) => {
            if (trail.groups.includes(value)) {
              return value;
            }
          });
        }
      }
    );

    watch(optionsGroups, (newGroup, oldGroup) => {
      if (allGroups.value && trail.branches.length != 0) {
        if (newGroup.length > oldGroup.length) {
          optionsGroups.value.filter((group) => {
            if (!trail.groups.includes(group.value)) {
              trail.groups.push(group.value);
            }
          });
        }
      }
    });

    function validateDate(val, type, start_date = null, end_date = null) {
      const regex = /^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}$/;
      const firstDayOfYear = new Date(new Date().getFullYear(), 0, 1);
      const formattedFirstDayOfYear = date.formatDate(firstDayOfYear, "MM-DD");
      const lastDayOfYear = new Date(new Date().getFullYear(), 11, 31);
      const formattedlastDayOfYear = date.formatDate(lastDayOfYear, "MM-DD");
      const formattedVal = date.formatDate(val, "MM-DD");

      let validate = date.isBetweenDates(
        formattedVal,
        formattedFirstDayOfYear,
        formattedlastDayOfYear
      );
      let message =
        "Por favor verifique a sua data passada para não ocorrer nenhum erro";

      if (!regex.test(val)) {
        validate = false;
        message = "A data passada está em um formato incoreto";
      }

      if (val.length === 10) {
        if (type === "start" && validate) {
          if (end_date) {
            if (val > end_date) {
              validate = false;
              message = "A sua data inicial não pode ser depois da data final";
            }
          }
          return new Promise((resolve, reject) => {
            resolve(validate || message);
          });
        }

        if (type === "end" && validate) {
          if (start_date) {
            if (val < start_date) {
              validate = false;
              message = "A sua data final não pode ser anterior a data inicial";
            }
          }
          return new Promise((resolve, reject) => {
            resolve(validate || message);
          });
        }
      }
    }

    async function _getEventPoint() {
      let EVENT_END_COURSE = 3;
      let response = await _pointServices.getEvent(EVENT_END_COURSE);
      pointEndCourse.value = response.data.value;
    }

    async function _getCategory() {
      await trailDataServices
        .getCategories(store.state.userdata.company_id)
        .then((response) => {
          listCategories.value = [];
          response.data.map((data) => {
            listCategories.value.push({
              label: data.name,
              value: data.id,
            });
          });
        })
        .catch((error) => {
          alertErro(error.response.data.toString());
        });
    }

    function selectedFilteredGroups() {
      if (allGroups.value) {
        optionsGroups.value.filter((group) => {
          if (!trail.groups.includes(group.value)) {
            trail.groups.push(group.value);
          }
          handleSelectionOfGroups();
        });
      } else {
        listUsers.value = [];
        trail.groups = [];
        trail.participants = [];
        optionsUsers.value = [];
        allUsers.value = false;
      }
    }

    function selectedAllUsers() {
      if (allUsers.value) {
        optionsUsers.value.forEach((user) => {
          trail.participants.push(user);
        });
      } else {
        while (trail.participants.length != 0) {
          trail.participants.pop();
        }
      }
    }

    async function addNewCategory(newCategoryName, event) {
      event.preventDefault();
      //loading.value = true;

      if (newCategoryName == "") {
        _alertNotify("Você precisa preencher o campo nome da categoria");
        loading = false;
        return;
      }

      const formData = new FormData();
      formData.append("name", newCategoryName);

      await trailDataServices
        .addCategories(formData, store.state.userdata.company_id)
        .then(async (response) => {
          await _getCategory();

          if (response.data){
            $q.notify({message: "Categoria criada com sucesso!", position: "top", type: "positive"});
          }

        })
        .catch((error) => {
          alertErro(error.response.data.toString());
        });
      newCategoryName = "";
    }

    function removeCategory(category) {
      $q.notify({
        message: `Deseja remover o categoria ${category.label}?`,
        color: "red-9",
        position: "bottom",
        actions: [
          {
            label: "Remover",
            color: "white",
            handler: () => {
              _removeCategory(category);
            },
          },
        ],
      });
    }

    async function _removeCategory(category) {
      await trailDataServices
        .removeCategories(category.value)
        .then(async () => {
          await _getCategory();
        })
        .catch((error) => {
          alertErro(error.response.data.toString());
        });
    }

    const sendEventRemoveImage = () => {
      imageUrl.value = "";
      refFileInput.value = null;
      file.value = "";
    };

    const onEventFilePicked = (event) => {
      const files = event.target.files;
      file.value = files[0];
      const filename = files[0].name;
      if (filename.lastIndexOf(".") <= 0) {
        return alert("Por favor adicione um arquivo válido");
      }
      const fileReader = new FileReader();
      fileReader.addEventListener("load", (event) => {
        imageUrl.value = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
    };

    const sendEventAddImage = () => {
      refFileInput.value.click();
    };

    async function validateAndAddTrail(event) {
      event.preventDefault();
      var isEmpty = false;
      if (
        trail.title == "" ||
        trail.short_desc == "" ||
        trail.description == "" ||
        trail.classification == "" ||
        trail.start_date == "" ||
        trail.end_date == "" ||
        trail.categoryId == null ||
        trail.course_type == null
      ) {
        isEmpty = true;
      }

      if (isEmpty) {
        $q.notify({
          message: "Você precisa preencher todos os campos obrigatórios.",
          color: "red-9",
          position: "top",
        });
        loading.value = false;
        return;
      }

      loading.value = true;

      const formData = new FormData();
      formData.append("file", file.value);
      formData.append("title", trail.title);
      formData.append("category_id", trail.categoryId.value);
      formData.append("short_desc", trail.short_desc);
      formData.append("description", trail.description);
      formData.append("classification_id", trail.classification);
      formData.append("start_date", trail.start_date);
      formData.append("end_date", trail.end_date);
      formData.append(
        "requisite_course_id",
        trail.requisite_course_id == null ? 0 : trail.requisite_course_id
      );
      formData.append(
        "pointFinished",
        trail.points == null || trail.points == "" ? 0 : trail.points
      );
      formData.append("status", status.value ? "Active" : "Disactive");
      formData.append("Edition_other_tutor", trail.editionOtherTutor);

      formData.append("Course_type", trail.course_type);

      const trailId = route.query.id;

      if (trailId == null) {
        if (trail.course_type == 1) {
          trail.groups.forEach((group) => {
            formData.append("GroupsId", group);
          });
        } else {
          trail.participants.forEach((participant) => {
            formData.append("UsersId", participant.value);
          });
        }
      }

      if (trailId == null) {
        await trailDataServices
          .addTrail(formData)
          .then((response) => {
            loading.value = false;
            router.push({ name: "knowledge" });
          })
          .catch((error) => {
            console.log(error.response.data.title);
            $q.notify({
              message: "Erro ao criar nova trilha",
              color: "red-9",
              position: "top",
            });
            loading.value = false;
          });
      } else {
        await trailDataServices
          .editTrail(trailId, formData)
          .then((response) => {
            loading.value = false;
            router.push({ name: "knowledge" });
          })
          .catch((error) => {
            $q.notify({
              message: error.response.data.toString(),
              color: "red-9",
              position: "top",
            });
            loading.value = false;
          });
      }
    }

    function _getCategoryById(categoryId) {
      listCategories.value.forEach((category) => {
        if (category.value == categoryId) {
          trail.categoryId = category;
        }
      });
    }

    async function GetTrail(trailId) {
      if (trailId == null) return null;
      var dataTrail = await trailDataServices.getTrail(trailId);
      const startDateformattedString = date.formatDate(
        dataTrail.data.start_date,
        "YYYY-MM-DD"
      );
      const endDateformattedString = date.formatDate(
        dataTrail.data.end_date,
        "YYYY-MM-DD"
      );

      trail.title = dataTrail.data.title;
      trail.short_desc = dataTrail.data.short_desc;
      trail.description = dataTrail.data.description;
      trail.classification = dataTrail.data.classification_id;
      trail.points = dataTrail.data.pointfinished;
      trail.requisite_course_id = dataTrail.data.requisite_course_id;
      trail.start_date = startDateformattedString;
      trail.end_date = endDateformattedString;
      trail.editionOtherTutor = dataTrail.data.edition_other_tutor;

      imageUrl.value = url_aws_bucket + dataTrail.data.course_image;
      status.value = dataTrail.data.status == "Active" ? true : false;
      trail.course_type = dataTrail.data?.course_type;
      _getCategoryById(dataTrail.data.category_id);
    }

    async function _getClassification() {
      loading.value = true;
      trailDataServices
        .getClassification()
        .then((response) => {
          response.data.map((item) => {
            classification.value.push({
              label: item.name,
              value: item.id,
            });
          });
          loading.value = false;
        })
        .catch((e) => {
          console.log(e);
          loading.value = false;
        });
    }

    async function _getCourses() {
      loading.value = true;
      trailDataServices
        .getAllCourses()
        .then((response) => {
          if (response.data) {
            response.data.map((item) => {
              courses.value.push({
                label: item.title,
                value: item.id,
              });
            });

            optionCourses.value = courses.value;
          }
          loading.value = false;
        })
        .catch((e) => {
          console.log(e);
          loading.value = false;
        });
    }

    function filterFnCourse(val, update) {
      if (val === "") {
        update(() => {
          courses.value = optionCourses.value;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        courses.value = optionCourses.value.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    }

    async function getAllGroups() {
      _GroupServices
        .listGroups()
        .then((response) => {
          response.data.map((item) => {
            listGroups.value.push({
              label: item.group_name,
              value: item.id,
              company_id: item.company_id,
              branch_id: item.branch_id,
            });
          });
          optionsGroups.value = listGroups.value;
        })
        .catch((error) => {
          console.log(error);
        });
    }

    async function getBranches() {
      await _GroupServices
        .listBranches()
        .then((response) => {
          response.data.map((item) => {
            if (item.status) {
              listBranches.value.push({
                label: item.name_branch,
                value: item.id,
              });
            }
          });
          optionListBranches.value = listBranches.value;
        })
        .catch((err) => {
          console.log(err);
        });
    }

    function filterFnBranches(val, update) {
      if (val === "") {
        update(() => {
          listBranches.value = optionListBranches.value;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        listBranches.value = optionListBranches.value.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    }

    function filterFnGroups(val, update) {
      if (val === "") {
        update(() => {
          if (trail.branches.length > 0) {
            optionsGroups.value = listGroups.value.filter((group) => {
              return trail.branches.includes(group.branch_id);
            });
          }
          optionsGroups.value = listGroups.value;
        });
      }

      update(() => {
        const needle = val.toLowerCase();
        optionsGroups.value = optionsGroups.value.filter((v) => {
          if (trail.branches.length > 0) {
            return (
              v.label.toLowerCase().indexOf(needle) > -1 &&
              trail.branches.includes(v.branch_id)
            );
          }
          return v.label.toLowerCase().indexOf(needle) > -1;
        });
      });
    }
    function filterFnUsers(val, update) {
      if (val === "") {
        update(() => {
          if (trail.branches.length > 0) {
            optionsUsers.value = listUsers.value.filter((users) => {
              return trail.branches.includes(users?.branch_id);
            });
          } else {
            optionsUsers.value = listUsers.value;
          }
        });
      }

      update(() => {
        const needle = val.toLowerCase();
        optionsUsers.value = optionsUsers.value.filter((v) => {
          if (trail.branches.length > 0) {
            return (
              v.label.toLowerCase().indexOf(needle) > -1 &&
              trail.branches.includes(v?.branch_id)
            );
          }
          return v.label.toLowerCase().indexOf(needle) > -1;
        });
      });
    }

    async function handleSelectionOfGroups() {
      if (
        trail.course_type == 2 &&
        trail.groups.length > 0 &&
        !isEditMode.value
      ) {
        loading.value = true;
        const formData = new FormData();

        trail.groups.forEach((id) => {
          formData.append("Groups", id);
        });

        await _userServices
          .getAllUserByGroup(formData)
          .then((response) => {
            response.data.map((user) => {
              let { id, first_name, last_name, branch_id, group_id } = user;
              listUsers.value.push({
                label: `${first_name} ${last_name}`,
                value: id,
                branch_id: branch_id,
                group_id: group_id,
              });
            });

            listUsers.value = listUsers.value.filter((item, index, self) => {
              return (
                index ===
                self.findIndex((t) => {
                  return t.value === item.value;
                })
              );
            });

            optionsUsers.value = listUsers.value;
            loading.value = false;
          })

          .catch((error) => {
            loading.value = false;
          });
      } else {
        optionsUsers.value = [];
        listUsers.value = [];
        trail.participants = [];
      }
    }

    return {
      filterFnGroups,
      filterFnBranches,
      breadcrumbs,
      pointEndCourse,
      imageUrl,
      file,
      validateAndAddTrail,
      listCategories,
      showDialogCategory,
      trail,
      allUsers,
      selectedAllUsers,
      filterFnCourse,
      loading,
      classification,
      courses,
      status,
      refFileInput,
      sendEventAddImage,
      sendEventRemoveImage,
      onEventFilePicked,
      addNewCategory,
      removeCategory,
      optionsGroups,
      listGroups,
      allGroups,
      optionListBranches,
      listBranches,
      selectedFilteredGroups,
      handleSelectionOfGroups,
      optionsUsers,
      isEditMode,
      filterFnUsers,
      validateDate,
      canUserAccessClass
    };
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  width: 100%;
  height: 100%;

  .title-form {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    opacity: 0.45;
  }

  .content-new-training {
    height: 75vh;
    overflow: auto;

    .content-thumb-training {
      height: 100%;

      .thumb-training {
        border-radius: 10px;

        .q-img {
          border-radius: 10px;
        }
      }

      .btn-thumb-training {
        .q-btn {
          text-transform: none !important;
          border-radius: 8px;
        }
      }

      .label-title-menus {
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #686868;
      }
    }

    .content-form-training {
      height: 100%;

      .form-input {
        .title-input-form {
          margin-bottom: 8px;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
          opacity: 0.45;
          &--required::after {
            content: " *";
            color: #fe5268;
          }
        }
      }
    }
  }

  label {
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    opacity: 0.45;
  }

  .container-groups {
    display: flex;
    flex-wrap: nowrap;
  }

  .container-users {
    display: flex;
    flex-wrap: nowrap;
  }

  .item-groups {
    width: calc(80% - 25px);
    margin-right: 25px;
  }

  .item-users {
    width: calc(80% - 25px);
    margin-right: 25px;
  }
  .container-participants {
    display: flex;
    flex-direction: column;
  }

  .container-participants > div {
    margin-bottom: 40px;
  }

  @media (min-width: 800px) {
    flex-direction: row;
  }
}
</style>
